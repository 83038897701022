import puzzle from "../../assets/images/puzzle.png";
import seeker from "../../assets/images/seeker.png";

const Details = () => {
  return (
    <section
      className="pt-8 pb-9 pt-md-11 pb-md-13 bg-light"
      id="como-funciona"
    >
      <div className="container">
        <div className="row">
          <div
            className="col-12"
            id="comoFunciona"
            tabIndex={-1}
            style={{ outline: "none" }}
          >
            <h2 className="mb-5 color-primary">Como funciona</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <a
                      href="#!"
                      className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                    >
                      <span>1</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3>Cadastre-se</h3>

                <p className="text-muted mb-6 mb-md-0">
                  Crie uma conta apenas com o seu e-mail{" "}
                  <a href="/login">aqui.</a> Acesse sua caixa de entrada e
                  confirme o cadastro. Você será redirecionado para a área
                  logada.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <a
                      href="#!"
                      className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                    >
                      <span>2</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3>Autorize as contas XP</h3>

                <p className="text-muted mb-6 mb-md-0">
                  Adicione um ou mais código de cliente (Contas diferentes da
                  XP) que deseja usar com sua conta Seeker. Só lembrando,{" "}
                  <b>nunca</b> pediremos sua senha.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <a
                      href="#!"
                      className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                    >
                      <span>3</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3>Baixe a extensão</h3>

                <p className="text-muted mb-6 mb-md-0">
                  Baixe a{" "}
                  <a
                    href="https://chrome.google.com/webstore/detail/seeker/coejmecobiacfjgodidbmiekgjddnici"
                    rel="noreferrer"
                    target="_blank"
                  >
                    extensão do Seeker
                  </a>{" "}
                  para o Google Chrome ou Microsoft Edge. Depois de baixada não
                  esqueça de clicar no ícone das extensões{" "}
                  <img
                    src={puzzle}
                    alt="Extensões"
                    style={{
                      height: "18px",
                      marginRight: "3px",
                      marginLeft: "3px",
                    }}
                  />
                  e dar um pin na extensão do Seeker{" "}
                  <img
                    src={seeker}
                    alt="Seeker"
                    style={{ height: "18px", marginRight: "5px" }}
                  />
                  pra ela ficar sempre visível no seu navegador.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-auto col-md-12">
                <div className="row no-gutters align-items-center mb-md-5">
                  <div className="col-auto">
                    <a
                      href="#!"
                      className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                    >
                      <span>4</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-md-12 ml-n5 ml-md-0">
                <h3>Renda fixa da XP</h3>

                <p className="text-muted mb-6 mb-md-0">
                  Acesse a página de{" "}
                  <a
                    href="https://experiencia.xpi.com.br/emissao-bancaria"
                    target="_blank"
                    rel="noreferrer"
                  >
                    renda fixa da XP
                  </a>
                  , os melhores horários são entre 9:30 e 10:30 da manhã.
                  Preencha os filtros de acordo com a sua estratégia e ative a
                  busca. Pronto, agora é só aguardar. Quando não quiser mais
                  buscar nenhum ativo, é só desativar a busca.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
