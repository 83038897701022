import { Slider } from "antd";
import { useState } from "react";
import { formatMoney } from "../../utils/func";

const Price = () => {
  const [value, setValue] = useState<number>(2000);
  const [tempo, setTempo] = useState(12);

  return (
    <>
      <section
        className="pt-8 pt-md-11 pb-10 pb-md-15 bg-primary"
        id="taxa-de-servico"
      >
        <div className="shape shape-blur-3 text-white">
          <svg
            viewBox="0 0 1738 487"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0h1420.92s713.43 457.505 0 485.868C707.502 514.231 0 0 0 0z"
              fill="url(#paint0_linear)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="0"
                y1="0"
                x2="1049.98"
                y2="912.68"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="currentColor" stopOpacity=".075"></stop>
                <stop
                  offset="1"
                  stopColor="currentColor"
                  stopOpacity="0"
                ></stop>
              </linearGradient>
            </defs>
          </svg>{" "}
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1 className="display-2 text-white">Simples, preço justo.</h1>

              <p className="lead text-white-80 mb-6 mb-md-8">
                Nossa cobrança é simples e transparente, e depende do tempo de
                vencimento do seu título.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <<-- SHAPE -->> */}
      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-light">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
              fill="currentColor"
            ></path>
          </svg>{" "}
        </div>
      </div>
      {/* <<-- PRICES -->> */}
      <section className="mt-n8 mt-md-n14">
        <div className="container">
          <div className="row gx-4">
            <div className="col-12 col-md-2" />
            <div className="col-12 col-md-8">
              <div className="card shadow-lg mb-6 mb-md-0">
                <div className="card-body">
                  <div className="text-center mb-3">
                    <span className="badge rounded-pill bg-primary-soft">
                      <span className="h4">0,02%*</span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-center"></div>

                  <p className="text-center text-muted mb-5">
                    * do valor investido multiplicado pela quantidade de meses
                  </p>

                  <p className="text-center text-muted mb-5">
                    Limitado ao máximo de 1,6%. Use nosso simulador abaixo.
                  </p>
                  <div className="card shadow-lg mb-5">
                    <div className="card-body">
                      {/* <div className="d-flex"> */}
                      <span>
                        Valor investido <b>{formatMoney(value || 0)}</b>
                      </span>
                      <Slider
                        max={100000}
                        min={0}
                        onChange={(value) => setValue(Number(value))}
                        tooltip={{
                          formatter: (value) => formatMoney(value || 0),
                        }}
                        value={value}
                      />

                      <span>
                        Tempo{" "}
                        <b>
                          {tempo} {tempo > 1 ? "meses" : "mês"}
                        </b>
                      </span>
                      <Slider
                        max={120}
                        min={1}
                        onChange={(value) => setTempo(Number(value))}
                        tooltip={{
                          formatter: (value = 1) =>
                            `${value} ${value > 1 ? "meses" : "mês"}`,
                        }}
                        value={tempo}
                      />
                      <span>
                        Taxa de corretagem{" "}
                        <b>
                          {formatMoney(value * Math.min(0.016, tempo * 0.0002))}
                        </b>
                      </span>
                      {/* </div> */}
                    </div>
                  </div>
                  <a href="/pagamento" className="btn w-100 btn-primary-soft">
                    A cobrança é feita de uma única vez automaticamente no
                    cartão de crédito cadastrado.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Price;
