import Cards from "react-credit-cards-2";
import "react-credit-cards-2/es/styles-compiled.css";
import { Card } from "../../store/mobx/Dashboard/model";
import { StyledContainer } from "./PreviewCard.style";
import type { IProps } from "./model";

const getCardExpiry = (card?: Card) => {
  if (!card) return "";
  const month = `0${card.expMonth}`; // Add extra 0 and remove if necessary
  const year = card.expYear;
  return `${month.slice(month.length - 2, month.length)}/${year}`;
};

const PreviewCard = ({ card }: IProps) => {
  return (
    <StyledContainer>
      <Cards
        cvc="***"
        expiry={getCardExpiry(card)}
        issuer={card?.brand}
        locale={{
          valid: "Validade",
        }}
        number={card ? `**** **** **** ${card.last4}` : ""}
        placeholders={{
          name: "",
        }}
        preview={true}
      />
    </StyledContainer>
  );
};

export default PreviewCard;
