import { chain } from "lodash";
import { Fatura } from "../pages/Faturas/model";

const LOCALE = "pt-BR";
const OPTIONS = {
  timeZone: "America/Sao_Paulo",
};

export const getDate = (dateStr: string) => {
  const date = new Date(dateStr + "-00:00"); // Force UTC timezone, so "toLocaleXXX" will do the math.
  return `${date.toLocaleDateString(LOCALE, OPTIONS)} ${date.toLocaleTimeString(
    LOCALE,
    OPTIONS
  )}`;
};

export const formatMoney = (value: number) => {
  return new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

type IProps = Pick<Fatura, "hasFailedPayments" | "status">;

export const getFaturasColor = (props: IProps) => {
  const { status } = props;
  if (paymentFailed(props)) return "red"; // Failed payment - Já tentou pagar mas deu erro.
  if (status === 1) return "#FFFA82"; // Pending yellow - Ainda não tentou pagar, não chegou a hora da cobrança.
  if (status === 2) return "blue"; // Paid - Pago.
  if (status === 4) return "green"; // Exempted - Isento.
  if (status === 8) return "green"; // Cancelled - Ordem cancelada.
};

export const paymentFailed = (props: IProps) => {
  const { hasFailedPayments, status } = props;
  return hasFailedPayments && status === 1;
};

export const getPaymentFailureMsg = (fatura: Fatura) => {
  return chain(fatura.payments)
    .filter((p) => p.success === false)
    .orderBy(["createdDate"], ["desc"])
    .first()
    .get("errorMessage")
    .value();
};
