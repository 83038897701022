import { Spin } from "antd";

const CenteredSpin = () => {
  return (
    <Spin
      size="large"
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
    />
  );
};

export default CenteredSpin;
