import { Button, Input, Space, Spin, Steps, Tag, Typography } from "antd";
import {
  CreditCardOutlined,
  LinkOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DashboardStoreContext } from "../../store/mobx/Dashboard";
import { observer } from "mobx-react-lite";
import { map } from "lodash";
import { StyledAccounts, StyledContainer } from "./ComoUsar.style";
import { updateAccount } from "../../actions/user";

const { Text } = Typography;

const ComoUsar = () => {
  const { user, updateCodes } = useContext(DashboardStoreContext);
  const [current, setCurrent] = useState(0);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrent(Number(localStorage.getItem("SEEKER-current-step") || 0));
  }, []);

  return (
    <StyledContainer>
      <Steps
        direction="vertical"
        current={current}
        onChange={(value) => {
          setCurrent(value);
          localStorage.setItem("SEEKER-current-step", `${value}`);
        }}
        items={[
          {
            title:
              "Instale a extensão do Seeker no Google Chrome ou Microsoft Edge",
            icon: <UserOutlined />,
            description: (
              <Button
                icon={<LinkOutlined style={{ marginRight: "5px" }} />}
                style={{ display: "flex", alignItems: "center" }}
                type="link"
              >
                <a
                  href="https://chrome.google.com/webstore/detail/seeker/coejmecobiacfjgodidbmiekgjddnici"
                  rel="noreferrer"
                  target="_blank"
                >
                  Seeker na Chrome Store
                </a>
              </Button>
            ),
          },
          {
            title:
              "Adicione os códigos de cliente que deseja usar com essa conta",
            icon: <SolutionOutlined />,
            description: (
              <>
                <Text italic mark>
                  Atenção: Nunca pediremos sua senha, assinatura eletrônica ou
                  token temporário. Nunca compartilhe essas informações com
                  ninguém.
                  <br />A extensão usa a assinatura eletrônica para executar a
                  compra, mas nunca a enviamos ao nosso sevidor.
                </Text>
                <StyledAccounts>
                  <Space direction="vertical">
                    {!user ? (
                      <Spin style={{ marginLeft: "10px" }} />
                    ) : (
                      <>
                        {map(user?.user.xpAccounts, ({ xpAccountCode }) => (
                          <Space direction="horizontal" key={xpAccountCode}>
                            <Tag color="#1EA1F1">{xpAccountCode}</Tag>
                            <Button
                              disabled={loading}
                              loading={loading}
                              onClick={async () => {
                                setLoading(true);
                                try {
                                  const { data } = await updateAccount(
                                    "DELETE",
                                    xpAccountCode
                                  );
                                  updateCodes(data);
                                } catch (error) {
                                } finally {
                                  setLoading(false);
                                }
                              }}
                            >
                              Remover
                            </Button>
                          </Space>
                        ))}

                        <Space direction="horizontal">
                          <Input
                            onChange={(e) => setCode(e.target.value)}
                            placeholder="Adicionar nova conta"
                            value={code}
                          />
                          <Button
                            disabled={loading || !code}
                            loading={loading}
                            onClick={async () => {
                              setLoading(true);
                              try {
                                const { data } = await updateAccount(
                                  "PUT",
                                  code
                                );
                                updateCodes(data);
                                setCode("");
                              } catch (error) {
                              } finally {
                                setLoading(false);
                              }
                            }}
                          >
                            Adicionar
                          </Button>
                        </Space>
                      </>
                    )}
                  </Space>
                </StyledAccounts>
              </>
            ),
          },
          {
            title: "Adicione um cartão de crédito",
            icon: <CreditCardOutlined />,
            description: (
              <>
                <p>
                  Entenda nosso{" "}
                  <a href="/#taxa-de-servico" target="_blank">
                    modelo de cobrança
                  </a>
                  .
                </p>
                <Button
                  icon={<LinkOutlined style={{ marginRight: "5px" }} />}
                  style={{ display: "flex", alignItems: "center" }}
                  type="link"
                >
                  <Link to="/pagamento" target="_blank">
                    Adicionar Cartão
                  </Link>
                </Button>
              </>
            ),
          },
          {
            title: "Agora é só usar a extensão",
            icon: <SmileOutlined />,
            description: (
              <>
                <p>
                  Faça o login no site da XP, idealmente a partir das 9:30 da
                  manhã.
                </p>
                <p>
                  O mercado de renda fixa deixa de ficar atrativo por volta das
                  10:30 da manhã, então você pode desligar a extensão fora desse
                  horário.
                </p>
                <p>
                  Você terminou sua configuração, nada mais precisa ser feito
                  aqui no dashboard. Boas compras! 🤑
                </p>
              </>
            ),
          },
        ]}
      />
    </StyledContainer>
  );
};

export default observer(ComoUsar);
