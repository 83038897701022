const Footer = () => {
  return (
    <footer className="py-8 py-md-11 bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <span
              style={{ color: "white", fontSize: "32px", fontWeight: "bold" }}
            >
              Seeker.
            </span>

            <p className="text-gray-700 mb-2">Renda Fixa mais eficiente.</p>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <h6 className="fw-bold text-uppercase text-gray-700">Products</h6>

            <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <a href="/#como-funciona" className="text-reset">
                  Como Funciona
                </a>
              </li>
              <li className="mb-3">
                <a href="/#taxa-de-servico" className="text-reset">
                  Taxa de Servico
                </a>
              </li>
              <li className="mb-3">
                <a href="/#faq" className="text-reset">
                  FAQ
                </a>
              </li>
              <li className="mb-3">
                <a
                  href="https://chrome.google.com/webstore/detail/seeker/coejmecobiacfjgodidbmiekgjddnici"
                  rel="noreferrer"
                  target="_blank"
                >
                  Baixar extensão
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <h6 className="fw-bold text-uppercase text-gray-700">Legal</h6>

            <ul className="list-unstyled text-muted mb-0">
              <li className="mb-3">
                <a href="/termos-de-uso" className="text-reset">
                  Termos de Uso
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <h6 className="fw-bold text-uppercase text-gray-700">Contato</h6>

            <ul className="list-unstyled text-muted mb-0">
              <li className="mb-3">
                <a
                  href="mailto:contato@seekerapp.com.br"
                  className="text-reset"
                >
                  contato@seekerapp.com.br
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
