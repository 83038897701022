import { useContext } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import type { INavBarProps } from "./model";
import {
  NavbarWrapper,
  NavItem,
  NavItemLabel,
  NavIcon,
  NavIconWrapper,
  StyledLogo,
} from "./Navbar.style";
import { useNavigate } from "react-router-dom";
import { personIcon } from "../../assets/images";
import { AuthContext } from "../../store/mobx/AuthStore";
import { seeker } from "../../assets/images/index";
import { Dropdown, MenuProps } from "antd";
// import * as posthog from "../../utils/posthog";

const onClick = async (key: string, signOut: () => Promise<void>) => {
  if (key === "1") {
    await signOut();
    window.location.href = "/login";
  }
};

const dropdownItems: MenuProps["items"] = [
  {
    key: "1",
    label: "Sair",
    icon: <LogoutOutlined />,
  },
];

const Navbar = (props: INavBarProps) => {
  const { items } = props;
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);

  return (
    <NavbarWrapper>
      {/* TODO: Colocar logo do seeker aqui! Atualmente ela existe, só que tem a mesma cor do background. */}
      <StyledLogo alt="Logo" onClick={() => navigate("/")} src={seeker} />
      {items?.length > 0 &&
        items.map((item) => (
          <NavItem
            key={item.id}
            onClick={() =>
              item.onClick ? item.onClick() : navigate(`/${item.path}`)
            }
            style={item.style}
          >
            <NavItemLabel>{item.label}</NavItemLabel>
          </NavItem>
        ))}
      <Dropdown
        menu={{
          items: dropdownItems,
          onClick: ({ key }) => onClick(key, signOut),
        }}
      >
        <NavItem public={props.public}>
          {!props.public && (
            <NavIconWrapper>
              <NavIcon src={personIcon}></NavIcon>
            </NavIconWrapper>
          )}
        </NavItem>
      </Dropdown>
    </NavbarWrapper>
  );
};

export default Navbar;
