import { posthog } from "posthog-js";

const { href, origin } = window.location;
const isLocalhost = href.includes("localhost");

/** Init posthog, noop in case of localhost */
export const initPosthog = () => {
  if (!isLocalhost) {
    posthog.init(process.env.REACT_APP_POSTHOG_KEY as string, {
      api_host: "https://app.posthog.com",
    });
  }
};

/** Identify posthog user, noop in case of localhost */
export const identifyUser = (email: string) => {
  if (!isLocalhost) {
    posthog.identify(email);
    posthog.people.set({ email });
  }
};

/** Reset posthog user information, noop in case of localhost */
export const resetUser = () => {
  if (!isLocalhost) {
    posthog.reset();
  }
};

/** Capture posthog event, noop in case of localhost */
export const captureEvent = (event: string, config: Record<string, any>) => {
  if (!isLocalhost) {
    posthog.capture(event, { ...config, env: origin });
  }
};
