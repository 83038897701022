import illustration from "../../assets/scss/img/illustrations/illustration-2.png";

const Welcome = () => {
  return (
    <section className="pt-4 pt-md-11">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            <img
              src={illustration}
              className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0"
              alt="..."
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
          <div
            className="col-12 col-md-7 col-lg-6 order-md-1"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h1 className="display-3 text-center text-md-start">
              Bem vindo ao <span className="text-primary">Seeker</span>. <br />
              Seu buscador de renda fixa.
            </h1>

            <p className="lead text-center text-md-start text-muted mb-6 mb-lg-8">
              O Seeker busca de forma rápida e segura os títulos com as melhores
              taxas na corretora <br />
              <b>XP Investimentos.</b>
            </p>

            <div className="text-center text-md-start">
              <a href="/login" className="btn btn-primary shadow lift me-1">
                Crie sua conta{" "}
                <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
              </a>
              <a href="/#como-funciona" className="btn btn-primary-soft lift">
                Saiba mais
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
