import puzzle from "../../assets/images/puzzle.png";
import seeker from "../../assets/images/seeker.png";

const FAQ = () => {
  return (
    <section className="py-8 py-md-11 bg-white border-bottom" id="faq">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-md-8 text-center">
            <span className="badge rounded-pill bg-primary-desat-soft mb-3">
              <span className="h6 text-uppercase">FAQ</span>
            </span>

            <h2>Perguntas frequentes.</h2>

            <p className="fs-lg text-muted mb-7 mb-md-9">
              Deixe-nos ajudar a responder às perguntas mais comuns que você
              possa ter.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h3>Como faço pra usar o Seeker?</h3>

            <p className="text-gray-800 mb-6 mb-md-8">
              O primeiro passo é criar uma conta na página de login usando
              apenas o seu e-mail. Um link de acesso ao Seeker será encaminhado
              para a sua caixa de entrada, que dará acesso a área logada. Depois
              disso,{" "}
              <a
                href="https://chrome.google.com/webstore/detail/seeker/coejmecobiacfjgodidbmiekgjddnici"
                rel="noreferrer"
                target="_blank"
              >
                baixe a extensão{" "}
              </a>
              do Seeker para o Google Chrome ou Microsoft Edge. Depois de
              baixada não esqueça de clicar no ícone das extensões{" "}
              <img
                src={puzzle}
                alt="Extensões"
                style={{
                  height: "18px",
                  marginRight: "3px",
                  marginLeft: "3px",
                }}
              />
              e dar um pin na extensão do Seeker{" "}
              <img
                src={seeker}
                alt="Seeker"
                style={{ height: "18px", marginRight: "5px" }}
              />
              pra ela ficar sempre visível no seu navegador.
            </p>

            <h3>
              Posso vincular mais de uma conta da XP a minha conta Seeker?
            </h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              Sim! Vincule quantas contas da XP quiser a sua conta Seeker. Quer
              liberar o acesso a extensão do Seeker a outras pessoas pela sua
              conta? Basta cadastrar os códigos de clientes na área logada,
              adicionar um cartão de crédito e elas já estarão liberadas pra
              buscar os melhores títulos de renda fixa.
            </p>

            <h3>Quais as formas de pagamento?</h3>
            <p className="text-gray-800 mb-6 mb-md-0">
              No momento aceitamos apenas cartão de crédito. A extensão do
              Seeker ficará ativa apenas quando um cartão de crédito válido
              estiver vinculado as contas cadastradas. Sempre que uma compra for
              feita pelo Seeker a cobrança da taxa de serviço será feita
              automaticamente.
            </p>
          </div>

          <div className="col-12 col-md-6">
            <h3>Como faço pra ter acesso ao histórico das minhas compras?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              Na área logada você terá acesso a todo o seu histórico de compras.
              Nele você pode ver os títulos comprados, o valor da taxa de
              serviço do Seeker, qual código de cliente que fez a compra e muito
              mais.
            </p>

            <h3>Os dados do meu cartão de crédito estão seguros?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              Não se preocupe, não guardamos os dados do seu cartão de crédito
              no nosso servidor. Usamos o{" "}
              <a href="https://stripe.com/br" target="_blank" rel="noreferrer">
                Stripe
              </a>
              , que é um Provedor de Serviços PCI certificado Nível 1. Este é o
              nível mais rigoroso de certificação disponível no setor de
              pagamentos. A Stripe é uma fintech do Vale do Silício avaliada em
              US$ 50 bilhões.
            </p>

            <h3>Qual o diferencial do Seeker?</h3>
            <p className="text-gray-800 mb-0">
              O primeiro é a segurança, nunca pedimos sua senha, portanto não
              temos nenhum acesso a sua conta. Além disso o Seeker fica
              constantemente buscando os títulos disponíveis e estará pronto pra
              comprar em questão de segundos, de acordo com os filtros
              escolhidos na extensão. Sabemos que os melhores títulos acabam
              rápido, e com o Seeker suas chances de comprar aumentam em 98%*.
              *Percentual ao tentar comprar os mesmos títulos pelo Seeker e
              manualmente, em uma quantidade de vezes estatísticamente válida.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
