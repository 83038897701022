import { createContext } from "react";
import { makeAutoObservable } from "mobx";
import type { Codes, User } from "./model";
import { getUser } from "../../../actions/user";

class DashboardStore {
  user?: User;

  constructor() {
    makeAutoObservable(this);

    getUser()
      .then(({ data }) => {
        this.setUser(data);
      })
      .catch((err) => {});
  }

  setUser = (user: User) => {
    this.user = user;
  };

  updateCodes = (codes: Codes) => {
    const user = { ...this.user };
    user.user!.xpAccounts = codes;
    this.setUser(user as User);
  };
}

export const DashboardStoreContext = createContext<DashboardStore>(
  {} as DashboardStore
);

export default DashboardStore;
