import Navbar from "../LandingPage/1-navbar";
import Footer from "../LandingPage/7-footer";

const codeStyles = {
  display: "flex",
  flexDirection: "column",
  fontSize: "10px",
  gap: "10px",
  marginBottom: "20px",
  marginLeft: "36px",
} as const;

const TermosDeUso = () => {
  return (
    <div>
      <Navbar />
      <section
        className="pt-8 pt-md-11 pb-8 pb-md-14"
        style={{ backgroundColor: "#F9FBFD", borderTop: "1px solid #F1F4F8" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h1 className="display-4 mb-2">Termos de Uso</h1>

              <p className="fs-lg text-gray-700 mb-md-0">
                Atualizado em 24/04/2023
              </p>
            </div>
            {/* <div className="col-auto">
              <a href="#!" className="btn btn-primary-soft">
                Imprimir
              </a>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="my-6 my-md-8" />
            </div>
          </div>
          <div
            className="row"
            style={{ textAlign: "justify", marginBottom: "50px" }}
          >
            <div className="col-12 col-md-12">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4>Tem uma dúvida?</h4>

                  <p className="fs-sm text-gray-800 mb-5">
                    Nos envie um email, ficaremos felizes em conversar com você.
                  </p>

                  {/* <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                    Whatsapp
                  </h6>

                  <p className="fs-sm mb-5">
                    <a href="tel:555-123-4567" className="text-reset">
                      (555) 123-4567
                    </a>
                  </p> */}

                  <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                    Email
                  </h6>

                  <p className="fs-sm mb-0">
                    <a
                      href="mailto:contato@seekerapp.com.br"
                      className="text-reset"
                    >
                      contato@seekerapp.com.br
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ textAlign: "justify" }}>
            <div className="col-12 col-md-12">
              {/* <p className="fs-lg text-gray-800 mb-6 mb-md-8">
                Below are our Terms of Service, which outline a lot of legal
                goodies, but the bottom line is it’s our aim to always take care
                of both you, as a customer, or as a seller on our platform.
              </p> */}
              <h3 className="mb-5">1. CONCORDÂNCIA COM OS TERMOS</h3>
              <p className="text-gray-800">
                Ao utilizar a ferramenta Seeker, e quaisquer de suas páginas, o
                usuário terá aceito automaticamente todos os Termos de Uso
                estabelecidos abaixo.
              </p>
              <p className="text-gray-800">
                Caso o usuário não concorde com estes Termos, solicitamos que o
                mesmo não realize seu cadastro nem acesse, instale, ou utilize,
                de qualquer forma, nenhuma página, conteúdo, informação ou
                serviço disponibilizado pelo Seeker.
              </p>

              <h3 className="mb-5">
                2. DIREITOS DO AUTOR E PROPRIEDADE INTELECTUAL
              </h3>
              <p className="text-gray-800">
                O logotipo Seeker, seu slogan e as informações contidas nas suas
                ferramentas e website, bem como os direitos de propriedade
                intelectual, seu conteúdo, páginas, softwares e sistemas são de
                propriedade do Seeker, ou de terceiros que licitamente cederam
                ou tenham adquirido seu direito de uso. São expressamente
                vedados, sob pena da lei, qualquer tipo de utilização, cópia,
                modificação, reprodução, distribuição, publicação parcial ou
                integral, comercial ou não de todo este material, salvo com a
                prévia e expressa autorização do Seeker.
              </p>
              <p className="text-gray-800">
                O Seeker provê ao usuário uma licença pessoal e intransferível
                para utilizar o conteúdo e informações disponibilizadas no
                aplicativo e website. Esta licença não permite que o usuário, ou
                que terceiros, com a permissão do usuário, copiem, modifiquem,
                criem textos derivados deste website ou do aplicativo do Seeker,
                bem como vendam, consignem, sublicenciem, concedam em garantia
                ou de qualquer outra forma transfiram qualquer direito com
                relação aos aplicativos e website do Seeker. O usuário também
                não poderá modificar este website de qualquer maneira com a
                finalidade de obter acesso não autorizado a ele. O usuário
                também não poderá acessar este website por qualquer meio que não
                seja a interface fornecida para tanto pelo Seeker.
              </p>

              <h3 className="mb-5">3. RESPONSABILIDADE DO USUÁRIO</h3>
              <p className="text-gray-800">
                O Seeker não se responsabiliza pelo fornecimento indevido de
                informações pessoais ou pelo uso indevido das funcionalidades de
                sua Plataforma, sendo de inteira responsabilidade do usuário, os
                eventuais danos causados a si ou a terceiros.
              </p>
              <p className="text-gray-800">
                É de inteira responsabilidade do usuário o uso adequado de todos
                os recursos da Internet, sem exceção. O Seeker não se
                responsabiliza por eventuais omissões ou imprecisões, nem por
                problemas de transmissão de dados que gerem atraso, interrupção,
                cessação da transmissão ou por outra falha não citada
                expressamente. Além disso, também não se responsabiliza pelo
                conteúdo de outros websites, cujos endereços estejam disponíveis
                no nosso próprio website/extensão, ou até mesmo pelo conteúdo
                dos sites em que o endereço do nosso site esteja disponível.
              </p>
              <p className="text-gray-800">
                O usuário está de acordo que cumprirá todas as leis aplicáveis,
                incluindo, sem limitação, as leis de privacidade, as leis de
                propriedade intelectual, as leis Anti-Spam, as leis de controle
                de exportação, as leis fiscais e os requisitos regulamentares.
                Concorda também que utilizará a plataforma exclusivamente para
                seu uso pessoal ou como administrador/representante de uma
                pessoa jurídica, e não de forma comercial e não tentará
                interferir no funcionamento da Plataforma de qualquer forma.
              </p>
              <p className="text-gray-800">
                Menores de idade somente poderão utilizar o sistema sob
                responsabilidade de seu representante ou assistente legal,
                conforme o caso, e observadas as políticas internas da XP
                Investimentos CCTVM S.A. pela qual realizam suas operações.
              </p>

              <h3 className="mb-5">4. PRESTAÇÃO DE SERVIÇO</h3>
              <p className="text-gray-800">
                Ao aceitar o presente termo de uso, o usuário concorda em
                conceder ao Seeker poderes para em seu nome comprar ativos de
                renda fixa na corretora XP Investimentos CCTVM S.A. A aquisição
                dos ativos, que ocorre programaticamente, leva em conta os
                parâmetros estabelecidos pelo usuário mediante configuração no
                aplicativo de extensão do Google Chrome ou Microsoft Edge,
                dentre os quais:
              </p>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>

                <p className="text-gray-800">
                  Valor mínimo e máximo a ser investido;
                </p>
              </div>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>

                <p className="text-gray-800">
                  Opção de ignorar um ou mais emissores;
                </p>
              </div>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>
                <p className="text-gray-800">
                  Tipo do ativo (CDB, LC, LCI ou LCA), indexador e rentabilidade
                  mínima do título;
                </p>
              </div>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>
                <p className="text-gray-800">
                  Prazo mínimo e máximo de vencimento do ativo;
                </p>
              </div>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>
                <p className="text-gray-800">
                  Emissores de ativos não desejados;
                </p>
              </div>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>
                <p className="text-gray-800">Score de risco mínimo e máximo;</p>
              </div>
              <p className="text-gray-800">
                O Seeker buscará os ativos de renda fixa e realizará operações
                de compra apenas quando expressamente ordenado pelo usuário (ao
                clicar no botão para ativar o aplicativo), nos termos por ele
                definido. Como os parâmetros para o investimento são definidos
                expressamente pelo usuário, o Seeker não adota ou oferece
                qualquer estratégia de investimentos própria, e se restringe a
                atender aos parâmetros configurados pelo usuário.
              </p>
              <p className="text-gray-800">
                A abertura de conta no Seeker, sem aceitação dos termos e/ou sem
                efetivação de investimento, não constitui por si só uma relação
                de prestação de serviços do Seeker com o usuário.
              </p>
              <p className="text-gray-800">
                A taxa de serviço do Seeker tem como variável o vencimento do
                ativo ao qual foi realizada a compra e o valor da compra.
                Funciona da seguinte forma: quando o Seeker encontrar um ativo
                que atenda a todos os parâmetros do usuário, o Seeker enviará
                uma ordem de compra a corretora e caso o investimento seja
                realizado com sucesso, o Seeker calculará a taxa de serviço a
                ser cobrada ao usuário pela utilização do aplicativo. A taxa de
                serviço é dada pela fórmula:
              </p>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>
                <p className="text-gray-800">
                  Custo = capital_investido * mín(1,6%; qtd_meses_ativo * 0,02%)
                </p>
              </div>
              <div style={codeStyles}>
                <code>capital_investido: valor total da compra do ativo</code>
                <code>
                  qtd_meses_ativo: quantidade de meses completos entre a data da
                  compra e a data de vencimento do ativo
                </code>
                <code>
                  mín(valor1, valor2): função que retorna o menor valor entre
                  valor1 e valor2
                </code>
              </div>
              <p className="text-gray-800">
                Abaixo temos alguns exemplos que detalham o cálculo da taxa de
                serviço:
              </p>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>
                <p className="text-gray-800">
                  R$ 5.000,00 com vencimento em 6 meses:
                </p>
              </div>
              <div style={codeStyles}>
                <code>Custo = 5.000,00 * mín(1,6%; 6 * 0,02%)</code>
                <code>Custo = 5.000,00 * 0,12%</code>
                <code>
                  <b>Custo = R$ 6,00</b>
                </code>
              </div>

              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>
                <p className="text-gray-800">
                  R$ 5.000,00 com vencimento em 12 meses:
                </p>
              </div>
              <div style={codeStyles}>
                <code>Custo = 5.000,00 * mín(1,6%; 12 * 0,02%)</code>
                <code>Custo = 5.000,00 * 0,24%</code>
                <code>
                  <b>Custo = R$ 12,00</b>
                </code>
              </div>

              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check"></i>
                </span>
                <p className="text-gray-800">
                  R$ 5.000,00 com vencimento em 96 meses:
                </p>
              </div>
              <div style={codeStyles}>
                <code>Custo = 5.000,00 * mín(1,6%; 96 * 0,02%)</code>
                <code>Custo = 5.000,00 * 1,6%</code>
                <code>
                  <b>Custo = R$ 80,00</b>
                </code>
              </div>
              <p className="text-gray-800">
                A cobrança das compras será feita de uma única vez, podendo
                ocorrer em horários distintos do dia, de acordo com o perfil do
                cliente e com as condições do mercado. Quando o usuário cadastra
                um cartão no Seeker, nós enviamos os dados diretamente a
                plataforma do Stripe e guardamos apenas o identificador gerado
                por ela. A plataforma de pagamentos Stripe é utilizada por
                milhões de empresas em todo o mundo e possui o mais alto nível
                de segurança. Ressaltamos que em nenhum momento o Seeker guarda
                as informações do cartão de crédito do usuário em nosso banco de
                dados.
              </p>
              <p className="text-gray-800">
                O Seeker não será responsabilizado por perda de capital ou de
                rentabilidade em caso de resgate antecipado do investimento
                perante a corretora XP Investimentos CCTVM S.A. O resgate
                antecipado fica sob responsabilidade do Cliente perante a
                Corretora, que possui suas próprias políticas de saída a
                mercado.
              </p>
              <p className="text-gray-800">
                O Seeker não garante que o conteúdo e os sistemas do site
                estarão disponíveis, atualizados e precisos a todo momento.
                Poderá haver casos de desatualização, inexatidão, ou de
                indisponibilidade, para manutenção planejada ou por motivos de
                força maior. O Seeker oferece ao usuário o sistema no estado em
                que se encontra, sem qualquer garantia.
              </p>
              <p className="text-gray-800">
                O Seeker poderá, com suporte em sua base histórica, mostrar ao
                usuário as taxas mais praticadas pelo mercado para determinados
                prazos de investimento e, estas, não deverão ser utilizadas como
                uma recomendação, indicação, análise ou consultoria de
                investimento, nem garantia de rentabilidade futura.
              </p>
              <p className="text-gray-800">
                O Seeker reserva-se o direito de alterar a taxa de serviço a
                qualquer momento, a exemplo de reduções promocionais, indicação
                ou isenção da taxa. Qualquer aumento da taxa de serviço além da
                aceita nesses termos de uso deverão ser comunicadas com 2 (duas)
                semanas de antecedência, e o usuário deverá aceitar os termos
                novamente se desejar continuar a usar o serviço do Seeker.
              </p>

              <h3 className="mb-5">5. FONTES E EXATIDÃO DAS INFORMAÇÕES</h3>
              <p className="text-gray-800">
                Dentro de nossa plataforma, oferecemos a visualização sobre o
                mercado de renda fixa observado por nossa plataforma dentro da
                corretora XP Investimentos CCTVM S.A. Em caso de conflito,
                prevalecem sempre as informações enviadas ou disponibilizadas
                pela referida instituição.
              </p>
              <p className="text-gray-800">
                O usuário assume total e exclusiva responsabilidade por
                quaisquer danos resultantes direta ou indiretamente do uso das
                informações contidas no website e aplicativo do Seeker.
              </p>
              <p className="text-gray-800">
                O Seeker não presta qualquer tipo de serviço de consultoria,
                análise, aconselhamento, recomendação, indicação, gestão ou
                recomendação de investimento, nem garante resultados ou
                rentabilidades em investimentos de qualquer natureza. A
                utilização do Seeker não cria qualquer vínculo ou compromisso
                perante o usuário.
              </p>

              <h3 className="mb-5">6. PRIVACIDADE E SIGILO</h3>
              <p className="text-gray-800">
                Os dados e informações obtidas são tratadas de acordo com as
                leis e regulamentações de proteção de dados aplicáveis. O Seeker
                realiza procedimentos técnicos (criptografia e ofuscação de
                dados) e organizacionais de forma a garantir que a sua
                informação esteja sempre segura. Regularmente treinamos e
                conscientizamos todos os nossos colaboradores sobre a
                importância da manutenção, salvaguarda e respeito da sua
                informação pessoal e consideramos inaceitável a violação da
                privacidade dos dados pessoais. Por isso, guardamos apenas os
                dados pessoais estritamente necessários para o funcionamento da
                ferramenta. Além disso, monitoramos constantemente e prevemos
                medidas disciplinares apropriadas para eventuais
                descumprimentos.
              </p>
              <p className="text-gray-800">
                O Seeker coleta os dados do usuário quando este realiza o
                cadastro no website e quando realiza algum investimento
                utilizando a extensão do Seeker para Google Chrome e/ou
                Microsoft Edge.
              </p>
              <p className="text-gray-800">
                Em razão dos serviços prestados, e após obter o seu
                consentimento, nos casos em que seja necessário e nos limites da
                legislação e regulamentação aplicáveis, o Seeker poderá coletar
                os seguintes dados:
              </p>
              <p className="text-gray-800">
                Dados Cadastrais: dados como nome, sobrenome, data de
                nascimento, endereço, e informações de contato (ex.: email,
                número de telefone, CPF e endereço), para fins de criação de
                conta e emissão de nota fiscal referentes a determinados
                serviços.
              </p>
              <p className="text-gray-800">
                Dados de Navegação: dados coletados enquanto você utiliza os
                serviços da Seeker, inclusive através da sua navegação nas
                plataformas da Seeker, como dados sobre a localização derivada
                do seu endereço de IP ou outros meios, geolocalização e outras
                informações de localização em tempo real, dados técnicos, como
                os computadores, telefones e outros dispositivos por meio do(s)
                qual(is) você instala ou acessa os serviços, e o desempenho do
                provedor, da rede e do dispositivo, e o tipo do navegador, o
                sistema operacional e a versão do aplicativo utilizado, quando
                aplicável.
              </p>
              <p className="text-gray-800">
                As informações inseridas pelo usuário no sistema não serão, em
                nenhuma hipótese, compartilhadas, vendidas, cedidas ou de
                qualquer outra forma disponibilizadas para uso de terceiros,
                exceto se o próprio usuário a solicitar, ou se por ventura, o
                Seeker for obrigado a ceder informações por decisão judicial.
              </p>
              <p className="text-gray-800">
                Alguns integrantes do Seeker podem ter acesso às informações do
                usuário, limitados ao estrito cumprimento de suas atividades
                profissionais.
              </p>
              <p className="text-gray-800">
                O Seeker poderá utilizar os meios de comunicação informados pelo
                Usuário para contatá-lo, quando necessário.
              </p>
              <p className="text-gray-800">
                O Seeker utiliza seus dados de acordo com a Política de
                Privacidade vinculada a estes Termos, com a qual você consentiu.
              </p>
              <p className="text-gray-800">
                Você autoriza o Seeker a contatá-lo por qualquer meio, inclusive
                telefônico, e-mail, SMS, correspondência, para enviar
                comunicações de seu interesse a respeito dos serviços do Seeker,
                em especial para prevenção de fraudes. Você autoriza, ainda, o
                envio de mensagens via SMS, e-mails e propostas referentes a
                oferta de serviços do Seeker.
              </p>

              <h3 className="mb-5">7. SEGURANÇA DA INFORMAÇÃO</h3>
              <p className="text-gray-800">
                O Seeker toma todas as medidas adequadas para proteger as
                informações que são enviadas ao nosso servidor. Eles ficam
                hospedados sob a infraestrutura de segurança da Amazon Web
                Services em São Paulo. As informações fornecidas trafegam e são
                armazenadas de forma segura, protegidas por criptografia e
                protocolos de segurança HTTPS/TLS.
              </p>
              <p className="text-gray-800">
                O usuário é o único e exclusivo responsável por todos os acessos
                realizados mediante uso de seu e-mail, bem como por todas as
                informações transmitidas durante o acesso. É responsabilidade do
                usuário manter seguro o acesso ao seu e-mail do cadastro.
              </p>
              <p className="text-gray-800">
                No caso do usuário perder acesso ao seu e-mail de cadastro, por
                favor entre em contato com a nossa equipe para que possamos
                desativar a sua conta e transferir os dados armazenados para um
                novo cadastro com um novo e-mail.
              </p>

              <h3 className="mb-5">8. ATUALIZAÇÃO E CONTINUIDADE DO SISTEMA</h3>
              <p className="text-gray-800">
                O Seeker reserva-se o direito de promover melhorias e
                modificações no seu website e aplicativos, com o objetivo de
                aprimorar as funcionalidades oferecidas, a partir das
                informações e sugestões enviadas pelos usuários. O usuário
                concorda que suas sugestões possam ser utilizadas pelo Seeker,
                em proveito de todos os demais usuários, sem qualquer
                expectativa de contraprestação individual.
              </p>
              <p className="text-gray-800">
                O Seeker poderá, ainda, descontinuar o funcionamento do Sistema
                a qualquer momento. Reserva-se também o direito de suspender ou
                excluir a conta de qualquer usuário, pelos motivos que julgar
                suficientes, sem que esteja obrigado a comunicar ou expor as
                razões de sua decisão e sem que isso gere qualquer direito de
                indenização ou ressarcimento.
              </p>
              <p className="text-gray-800">
                Os Termos de Uso e Política de Privacidade poderão ser alterados
                a qualquer momento, bastando, para tanto, publicar uma versão
                revisada no website. Dessa forma, esses documentos sempre
                estarão disponíveis aos usuários no website e pode também ser
                solicitada por meio de contato com nossa equipe.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x text-dark">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
              fill="currentColor"
            ></path>
          </svg>{" "}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermosDeUso;
