import { observer } from "mobx-react-lite";
import { Outlet, Navigate } from "react-router-dom";
import CenteredSpin from "../../../components/CenteredSpin";
import { useIsAuthenticated } from "../../../utils/useIsAuthenticated";

const PrivateRoute = () => {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated === undefined) {
    return <CenteredSpin />;
  } else if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  } else {
    return <Outlet />;
  }
};

export default observer(PrivateRoute);
