import { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button } from "antd";
import type { IProps } from "./model";
import { StyledFormContainer } from "./Stripe.style";

const SetupForm = ({ userHasCard }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/pagamento`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledFormContainer>
        <PaymentElement />
        <Button
          block
          disabled={!stripe}
          htmlType="submit"
          style={{ margin: "10px 0" }}
          type="primary"
        >
          {userHasCard ? "Substituir cartão" : "Cadastrar cartão"}
        </Button>
        {errorMessage && <div>{errorMessage}</div>}
      </StyledFormContainer>
    </form>
  );
};

export default SetupForm;
