import { ENDPOINTS } from "../../utils/endpoints";
import API_CALL from "../api";

export const authAPI = async () => {
  try {
    return await API_CALL({
      method: "POST",
      path: ENDPOINTS.login,
    });
  } catch (error) {}
};
