import { useRef, useState } from "react";
import { ConfigProvider, Layout, Menu, Typography } from "antd";
import {
  StyledSiderContainer,
  StyledSiderHeader,
  StyledMenuFoldOutlined,
  StyledMenuUnfoldOutlined,
  StyledNavigation,
} from "./Sider.style";
import { observer } from "mobx-react-lite";
import type { MenuProps } from "antd";
import { Link } from "react-router-dom";
import {
  CreditCardOutlined,
  DollarOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const { Sider: SiderAntd } = Layout;
const { Title } = Typography;
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    label,
    key,
    icon,
    children,
    title: label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(
    "Como Usar",
    "0",
    <Link to="/como-usar">
      <QuestionCircleOutlined />
    </Link>
  ),
  getItem(
    "Pagamento",
    "1",
    <Link to="/pagamento">
      <CreditCardOutlined />
    </Link>
  ),
  getItem(
    "Faturas",
    "2",
    <Link to="/faturas">
      <DollarOutlined />
    </Link>
  ),
  // getItem("User", "sub1", <UserOutlined />, [
  //   getItem("Tom", "3"),
  //   getItem("Bill", "4"),
  //   getItem("Alex", "5"),
  // ]),
  // getItem("Team", "sub2", <TeamOutlined />, [
  //   getItem("Team 1", "6"),
  //   getItem("Team 2", "8"),
  // ]),
  // getItem("Files", "9", <FileOutlined />),
];

const getSelectedKey = (pathname: string) => {
  switch (pathname) {
    case "/como-usar":
      return "0";
    case "/pagamento":
      return "1";
    case "/faturas":
      return "2";
    default:
      return "0";
  }
};

const Sider = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const filtersRef = useRef<HTMLDivElement>(null);

  return (
    <SiderAntd
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      trigger={null}
      // width={250}
    >
      <StyledSiderContainer collapsed={collapsed}>
        {collapsed ? (
          <StyledMenuFoldOutlined onClick={() => setCollapsed(false)} />
        ) : (
          <>
            <StyledNavigation ref={filtersRef}>
              <ConfigProvider
                getPopupContainer={() => filtersRef.current as HTMLDivElement}
              >
                <StyledSiderHeader>
                  <Title level={3}>Seeker</Title>
                  <StyledMenuUnfoldOutlined
                    onClick={() => setCollapsed(true)}
                  />
                </StyledSiderHeader>
                <Menu
                  items={items}
                  mode="inline"
                  selectedKeys={[getSelectedKey(location.pathname)]}
                  style={{ borderInlineEnd: "none" }}
                  theme="light"
                />
              </ConfigProvider>
            </StyledNavigation>
          </>
        )}
      </StyledSiderContainer>
    </SiderAntd>
  );
};

export default observer(Sider);
