import { Navigate, Route, Routes } from "react-router-dom";
import { PublicRoute, PrivateRoute } from "./routeWrappers";
import {
  Auth,
  ComoUsar,
  Home,
  LandingPage,
  Login,
  Card,
  TermosDeUso,
} from "../pages";
import Faturas from "../pages/Faturas";

const Router = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="login" element={<Login />} />
        <Route path="termos-de-uso" element={<TermosDeUso />} />
        <Route element={<Home public />}>
          <Route path="auth" element={<Auth />} />
        </Route>
      </Route>

      <Route element={<PrivateRoute />}>
        <Route element={<Home />}>
          <Route path="como-usar" element={<ComoUsar />} />
          <Route path="faturas" element={<Faturas />} />
          <Route path="pagamento" element={<Card />} />
          <Route path="*" element={<Navigate to={"como-usar"} replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
