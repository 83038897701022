import styled, { css } from "styled-components";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { SiderContainerProps } from "./model";
import CollapseBox from "../CollapseBox";

export const StyledNavigation = styled.div`
  width: 100%;
`;

export const StyledFiltersActions = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
  margin: 15px 15px 0 0;
`;

export const StyledSiderContainer = styled.div<SiderContainerProps>`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  ${(props) =>
    props.collapsed &&
    css`
      text-align: center;
      padding-right: 15px;
    `}
`;

export const StyledSiderHeader = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
`;

export const StyledCollapseBox = styled(CollapseBox)`
  margin-right: 15px;
`;

export const StyledMenuFoldOutlined = styled(MenuFoldOutlined)`
  color: blue;
`;

export const StyledMenuUnfoldOutlined = styled(MenuUnfoldOutlined)`
  color: blue;
`;

export const StyledSpace = styled(Space)`
  padding-bottom: 16px;
`;
