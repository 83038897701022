import { Result } from "antd";
import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../store/mobx/AuthStore";
import illustration from "../../assets/scss/img/illustrations/illustration-2.png";
import CenteredSpin from "../../components/CenteredSpin";
import { useIsAuthenticated } from "../../utils/useIsAuthenticated";

// import * as posthog from "../../utils/posthog";

enum AuthState {
  IDLE,
  AUTHENTICATING,
  AUTHENTICATED,
}

enum ResultState {
  IDLE,
  SUCCESS,
  FAILURE,
}

const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  const { magic } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [resultState, setResultState] = useState(ResultState.IDLE);
  const [authState, setAuthState] = useState(AuthState.IDLE);

  if (isAuthenticated === undefined) {
    return <CenteredSpin />;
  }

  if (isAuthenticated) {
    return <Navigate to="/como-usar" />;
  }

  const handleClick = async () => {
    setAuthState(AuthState.AUTHENTICATING);
    setResultState(ResultState.IDLE);
    const redirectURI = `${origin}/auth`;

    if (email) {
      try {
        // posthog.identifyUser(email);
        // posthog.captureEvent("login-try", { email });
        await magic.auth.loginWithMagicLink({ email, redirectURI });

        // posthog.captureEvent("login-succeeded", { email });
        setAuthState(AuthState.AUTHENTICATED);
        setResultState(ResultState.SUCCESS);
      } catch (error) {
        // posthog.captureEvent("login-failed", { email });
        setAuthState(AuthState.IDLE);
        setResultState(ResultState.FAILURE);
      }
    }
  };

  const isAuthenticating = authState === AuthState.AUTHENTICATING;

  return (
    <section className="section-border border-primary">
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center gx-0 min-vh-100">
          <div className="col-8 col-md-6 col-lg-6 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">
            <img src={illustration} alt="..." className="img-fluid" />
          </div>
          <div className="col-12 col-md-5 col-lg-5 order-md-1 mb-auto mb-md-0 pb-8 py-md-11">
            <div
              className="text-primary"
              style={{
                fontSize: "32px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Seeker.
            </div>

            <p className="mb-6 text-center text-muted">
              Use seu e-mail para entrar. Sua conta será criada automaticamente
              caso seja seu primeiro acesso.
            </p>

            <form className="mb-6">
              <div className="form-group">
                <label className="form-label" htmlFor="e-mail">
                  E-mail
                </label>
                <input
                  autoFocus
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  id="e-mail"
                  name="email"
                  placeholder="Digite seu e-mail"
                  type="e-mail"
                  value={email}
                />
              </div>

              <p className="mb-6 text-center text-muted">
                Ao entrar, você concorda com nossos{" "}
                <a href="/termos-de-uso" target="_blank">
                  termos de uso
                </a>
                .
              </p>

              {isAuthenticating ? (
                <button
                  className="btn w-100 btn-primary"
                  disabled
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                  type="button"
                >
                  <span
                    aria-hidden="true"
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                  ></span>
                  Autenticando...
                </button>
              ) : (
                <button
                  className="btn w-100 btn-primary"
                  disabled={
                    !email ||
                    isAuthenticating ||
                    authState === AuthState.AUTHENTICATED
                  }
                  onClick={handleClick}
                  type="submit"
                >
                  Entrar
                </button>
              )}
            </form>

            {resultState === ResultState.SUCCESS && (
              <Result
                extra={[<a href="/como-usar">Navegar para a área logada.</a>]}
                status="success"
                title="Você está autenticado."
              />
            )}

            {resultState === ResultState.FAILURE && (
              <Result
                status="error"
                title="Verifique seu e-mail e tente novamente."
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
