import styled from "styled-components";

export const StyledContainer = styled.div`
  overflow-y: auto;
  padding: 3rem;
  min-width: 500px;
`;

export const StyledAccounts = styled.div`
  margin-top: 15px;
`;
