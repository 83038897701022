import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../store/mobx/AuthStore";

/**
 * This function returns a boolean value indicating whether the user is authenticated or not using the
 * Magic authentication library in a React app.
 *
 * Este hook é usado em:
 *
 * PrivateRoute pra redirecionar pra /login se não autenticado.
 *
 * Login pra redirecionar pra /como-usar se autenticado.
 */
export const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const { magic } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      const isLoggedIn = await magic.user.isLoggedIn();
      setIsAuthenticated(isLoggedIn);
    })();
  }, [magic]);

  return isAuthenticated;
};
