import { AxiosResponse } from "axios";
import { ENDPOINTS } from "../../utils/endpoints";
import API_CALL from "../api";
import { Fatura } from "../../pages/Faturas/model";

export const getFaturas = async (): Promise<AxiosResponse<Fatura[], any>> => {
  return await API_CALL({
    method: "GET",
    path: ENDPOINTS.faturas,
  });
};
