import { IApiCall } from "./model";
import axios, { type AxiosRequestConfig } from "axios";
import { Magic } from "magic-sdk";
import { SEEKER_STORAGE_KEY } from "../../utils/constants";

const shouldRequestNewToken = (token: string | null | undefined) => {
  if (!token) return true;

  const config = JSON.parse(JSON.parse(atob(token))[1]);
  // NOTE: didToken (o token guardado no localStorage), tem validade de 15 minutos.
  // Se expirou, pegue um token novo, caso contrário, use o que tá na storage.
  if (new Date(config.ext * 1000) > new Date()) {
    return false;
  }

  return true;
};

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const API_CALL = async (requestParams: IApiCall) => {
  let token = localStorage.getItem(SEEKER_STORAGE_KEY);

  if (shouldRequestNewToken(token)) {
    const magic = new Magic(process.env.REACT_APP_MAGIC_LINK || "");
    token = await magic.user.generateIdToken();
    localStorage.setItem(SEEKER_STORAGE_KEY, token);
  }

  const {
    method,
    path,
    body = {},
    headers = {},
    queryParams = {},
  } = requestParams;

  const config: AxiosRequestConfig = {
    url: `${BASE_URL}/${path}`,
    method: method,
    headers: {
      Authorization: `${token}`,
      ...headers,
    },
    data: body,
    params: queryParams,
  };

  return axios(config);
};

export default API_CALL;
