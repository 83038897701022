import { Spin, Timeline, Typography } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { getFaturas } from "../../actions/faturas";
import { Fatura } from "./model";
import { StyledContainer, StyledFaturaContainer } from "./Faturas.style";
import {
  formatMoney,
  getDate,
  getFaturasColor,
  getPaymentFailureMsg,
  paymentFailed,
} from "../../utils/func";
import { orderBy } from "lodash";

const Faturas = () => {
  const [faturas, setFaturas] = useState<Fatura[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getFaturas()
      .then(({ data }) => {
        const faturas = orderBy(
          data,
          [(obj) => new Date(obj.createdDate)],
          ["desc"]
        );
        setFaturas(faturas);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (error) {
    return (
      <StyledContainer>
        <Typography.Title level={3}>
          Um erro inesperado ocorreu, por favor tente novamente mais tarde.
        </Typography.Title>
      </StyledContainer>
    );
  }

  if (loading) {
    return (
      <StyledContainer>
        <Spin size="large" />
      </StyledContainer>
    );
  }

  if (!faturas) {
    return (
      <StyledContainer>
        <Typography.Title level={3}>
          Você não possui faturas. Saiba como usar nosso app{" "}
          <a href="/como-usar">aqui.</a>
        </Typography.Title>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <div>
        <Timeline
          items={faturas.map((fatura) => ({
            color: getFaturasColor(fatura),
            label: getDate(fatura.createdDate),
            children: (
              <StyledFaturaContainer isCancelled={fatura.status === 8}>
                {paymentFailed(fatura) && (
                  <Typography.Text type="danger">
                    <b>Erro:</b> {getPaymentFailureMsg(fatura)} Favor atualizar
                    o <a href="/pagamento">cartão de crédito.</a>
                  </Typography.Text>
                )}
                <div>
                  <b>Ativo:</b> {fatura.description}
                </div>
                <div>
                  <b>Valor:</b> {formatMoney(fatura.value)}{" "}
                  {fatura.status === 4 && "(Pagamento isento)"}
                  {fatura.status === 8 && "(Ordem cancelada)"}
                </div>
                <div>
                  <b>Código de cliente:</b> {fatura.xpAccountCode}
                </div>
              </StyledFaturaContainer>
            ),
          }))}
          mode="left"
          style={{ paddingRight: "100px" }}
        />
      </div>
    </StyledContainer>
  );
};

export default observer(Faturas);
