// TODO: Comentando cada linha e remover os packages sem uso do arquivo abaixo.
import "../../assets/js/theme";
import "../../assets/scss/libs.scss";
import "../../assets/scss/theme.scss";
import Navbar from "./1-navbar";
import Welcome from "./2-welcome";
import Features from "./3-features";
import Details from "./4-details";
import Price from "./5-price";
import FAQ from "./6-faq";
import Footer from "./7-footer";

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <Welcome />
      <Features />
      <Details />
      <Price />
      <FAQ />
      <Footer />
    </div>
  );
};

export default LandingPage;
