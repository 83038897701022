import styled from "styled-components";

export const StyledMain = styled.main`
  align-items: flex-start;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  padding: 3rem;
  width: 100%;

  @media (max-width: 1000px) {
    grid-column-gap: 20px;
    grid-row-gap: 50px;
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;
    row-gap: 50px;
  }
`;

export const StyledSpinContainer = styled.main`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledElementContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
