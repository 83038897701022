import styled, { css } from "styled-components";
import { TNavItem } from "./model";

export const StyledLogo = styled.img`
  cursor: pointer;
  padding: 8px;
`;

export const NavbarWrapper = styled.div`
  background: #1ea1f1;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-between;
  padding: 4px 5px;
  width: 100%;
`;

export const NavItem = styled.div<TNavItem>`
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 100%;
  min-width: 90px;
  padding-left: 24px;
  padding-right: 24px;

  &:hover {
    border: 1px solid white;
  }

  ${(props) =>
    props.public &&
    css`
      cursor: auto;
      &:hover {
        background: transparent;
      }
    `}
`;

export const NavItemLabel = styled.label`
  margin: auto;
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: inherit;
`;

export const NavIconWrapper = styled.div`
  border: 2px solid white;
  border-radius: 25px;
  width: 24px;
  margin: auto;
  height: 24px;
  display: flex;
`;

export const NavIcon = styled.img`
  width: 13px;
  height: 11px;
  margin: auto;
`;
