const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container">
        {/* <!-- Brand --> */}
        <span
          className="text-primary"
          style={{ fontSize: "32px", fontWeight: "bold" }}
        >
          <a href="/" style={{ textDecoration: "none" }}>
            Seeker.
          </a>
        </span>

        {/* <!-- Toggler --> */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* <!-- Collapse --> */}
        <div className="collapse navbar-collapse" id="navbarCollapse">
          {/* <!-- Toggler --> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fe fe-x"></i>
          </button>

          {/* <!-- Navigation --> */}
          <ul className="navbar-nav ms-auto">
            <li className="nav-item dropdown">
              <a className="nav-link" href="/#como-funciona">
                Como funciona
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" href="/#taxa-de-servico">
                Taxa de Serviço
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" href="/#faq">
                FAQ
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="https://chrome.google.com/webstore/detail/seeker/coejmecobiacfjgodidbmiekgjddnici"
                rel="noreferrer"
                target="_blank"
              >
                Baixar extensão
              </a>
            </li>
          </ul>

          {/* <!-- Button --> */}
          <a
            className="navbar-btn btn btn-sm btn-primary lift ms-auto"
            href="/login"
          >
            Entrar
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
