import styled, { css } from "styled-components";
import { GRAY } from "../../utils/colors";
import { TBoxWrapper } from "./model";

export const BoxWrapper = styled.div<TBoxWrapper>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${GRAY};
  margin-bottom: 16px;

  ${(props) =>
    props.hideBorderBottom &&
    css`
      border-bottom: unset;
    `}
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const BoxHeaderIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #d6e1fd;
  display: flex;
  svg {
    margin: auto;
    width: 16px;
    color: #0c3bb9;
    font-weight: bolder;
  }
  margin-right: 8px;
  cursor: pointer;
`;

export const BoxHeaderLabel = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  color: #192129;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  user-select: none;
`;

export const ArrowLabel = styled.label`
  margin: auto 5px auto auto;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
  margin-bottom: 16px;
  > * {
    margin-right: 16px;
  }

  *:last-child {
    margin-right: 0;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  border: 1px solid #0c3bb9;
  border-radius: 4px;
  padding: 8px;
  flex-direction: column;
  width: 100%;
`;

export const ContainerInnerSegment = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelInputWrapper = styled.div`
  display: flex;

  > * {
    margin-right: 16px;
  }

  *:last-child {
    margin-right: 0;
  }
`;
