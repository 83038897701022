import { Spin } from "antd";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../store/mobx/AuthStore";
import { authAPI } from "../../actions/auth";
import { StyledContainer } from "./Auth.styles";
// import { posthog } from "posthog-js";

const Auth = () => {
  const navigate = useNavigate();
  const { magic, setToken } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      const didToken = await magic.auth.loginWithCredential();

      try {
        if (!didToken) {
          throw new Error("Erro ao fazer login, por favor tente mais tarde.");
        }
        setToken(didToken);
        await authAPI();
        navigate("/como-usar");
      } catch (e) {
        console.error(e);
        navigate("/login");
        // posthog.capture("ATC-token-failed", {
        //   env: origin,
        // });
      }
    })();
  }, [magic, navigate, setToken]);

  return (
    <StyledContainer>
      <Spin size="large" tip="Autenticando..." />
    </StyledContainer>
  );
};

export default Auth;
