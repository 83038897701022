import {
  BoxWrapper,
  BoxHeader,
  BoxHeaderIconWrapper,
  BoxHeaderLabel,
  ArrowLabel,
} from "./CollapseBox.style";
import type { TCollapseBoxProps } from "./model";
import { useState } from "react";

const CollapseBox = (props: TCollapseBoxProps) => {
  const {
    action,
    children,
    className,
    hideBorderBottom,
    initialCollapse,
    title,
    TitleIcon,
  } = props;
  const [collapse, setCollapse] = useState<boolean>(initialCollapse ?? true);

  return (
    <BoxWrapper className={className} hideBorderBottom={hideBorderBottom}>
      <BoxHeader data-testid="collapse" onClick={() => setCollapse(!collapse)}>
        <BoxHeaderIconWrapper>
          <TitleIcon />
        </BoxHeaderIconWrapper>
        <BoxHeaderLabel>{title}</BoxHeaderLabel>
        {action}
        <ArrowLabel>{collapse ? "▲" : "▼"} </ArrowLabel>
      </BoxHeader>
      {collapse ? <></> : children}
    </BoxWrapper>
  );
};

export default CollapseBox;
