import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { IAlert } from "./models";

class AlertStore {
  alert?: IAlert = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setAlert = (props: IAlert, shouldTimeout: boolean = true) => {
    this.alert = props;
    if (shouldTimeout) {
      setTimeout(() => {
        this.alert = undefined;
      }, 5000);
    }
  };
}

export const AlertContext = createContext<AlertStore>({} as AlertStore);

export default AlertStore;
