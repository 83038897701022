const Features = () => {
  return (
    <section className="py-8 py-md-11 border-bottom" data-aos="fade-up">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="icon text-primary mb-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M0 0h24v24H0z" />
                  <path
                    d="M7 3h10a4 4 0 110 8H7a4 4 0 110-8zm0 6a2 2 0 100-4 2 2 0 000 4z"
                    fill="#335EEA"
                  />
                  <path
                    d="M7 13h10a4 4 0 110 8H7a4 4 0 110-8zm10 6a2 2 0 100-4 2 2 0 000 4z"
                    fill="#335EEA"
                    opacity=".3"
                  />
                </g>
              </svg>
            </div>

            <h3>Construido por investidores</h3>

            <p className="text-muted mb-6 mb-md-0">
              O Seeker foi desenvolvido pra resolver um problema pessoal, por
              isso entedemos a sua necessidade.
            </p>
          </div>
          <div className="col-12 col-md-4">
            <div className="icon text-primary mb-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M0 0h24v24H0z" />
                  <path
                    d="M5.5 4h4A1.5 1.5 0 0111 5.5v1A1.5 1.5 0 019.5 8h-4A1.5 1.5 0 014 6.5v-1A1.5 1.5 0 015.5 4zm9 12h4a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-1a1.5 1.5 0 011.5-1.5z"
                    fill="#335EEA"
                  />
                  <path
                    d="M5.5 10h4a1.5 1.5 0 011.5 1.5v7A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-7A1.5 1.5 0 015.5 10zm9-6h4A1.5 1.5 0 0120 5.5v7a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-7A1.5 1.5 0 0114.5 4z"
                    fill="#335EEA"
                    opacity=".3"
                  />
                </g>
              </svg>
            </div>

            <h3>Seguro</h3>

            <p className="text-muted mb-6 mb-md-0">
              Não fazemos login na sua conta (nem pedimos sua senha ou código
              temporário de acesso), apenas buscamos as melhoras taxas na sua
              área logada da XP.
            </p>
          </div>
          <div className="col-12 col-md-4">
            <div className="icon text-primary mb-3">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M0 0h24v24H0z" />
                  <path
                    d="M17.272 8.685a1 1 0 111.456-1.37l4 4.25a1 1 0 010 1.37l-4 4.25a1 1 0 01-1.456-1.37l3.355-3.565-3.355-3.565zm-10.544 0L3.373 12.25l3.355 3.565a1 1 0 01-1.456 1.37l-4-4.25a1 1 0 010-1.37l4-4.25a1 1 0 011.456 1.37z"
                    fill="#335EEA"
                  />
                  <rect
                    fill="#335EEA"
                    opacity=".3"
                    transform="rotate(15 12 12)"
                    x="11"
                    y="4"
                    width="2"
                    height="16"
                    rx="1"
                  />
                </g>
              </svg>
            </div>

            <h3>Fatura simplificada</h3>

            <p className="text-muted mb-0">
              Somos conectados com um dos maiores aplicativos de pagamentos
              online do mundo, e nunca guardamos os dados do seu cartão conosco.
              Saiba mais em{" "}
              <a href="https://stripe.com/br" target="_blank" rel="noreferrer">
                Stripe
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
