import AuthStore, { AuthContext } from "../../store/mobx/AuthStore";
import { IAuthProvider } from "./model";

const AuthProvider = ({ children }: IAuthProvider) => {
  return (
    <AuthContext.Provider value={new AuthStore()}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
