import { Magic } from "magic-sdk";
import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { SEEKER_STORAGE_KEY } from "../../../utils/constants";
import axios from "axios";

class AuthStore {
  magic = new Magic(process.env.REACT_APP_MAGIC_LINK || "");

  constructor() {
    makeAutoObservable(this);

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.signOut();
        }
        return Promise.reject(error);
      }
    );
  }

  setToken = (token: string | null) => {
    token
      ? localStorage.setItem(SEEKER_STORAGE_KEY, token)
      : localStorage.removeItem(SEEKER_STORAGE_KEY);
  };

  signOut = async () => {
    this.setToken(null);
    await this.magic.user.logout();
  };
}

export const AuthContext = createContext<AuthStore>({} as AuthStore);

export default AuthStore;
