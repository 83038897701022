import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { AlertWrapper, HomeWrapper, StyledAlert } from "./Home.style";
// import { NAV_BAR_DATA } from "../../utils/constants";
import { IHome } from "./model";
import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { AlertContext } from "../../store/mobx/Alert";
import { Layout } from "antd";
import DashboardStore, {
  DashboardStoreContext,
} from "../../store/mobx/Dashboard";
import Sider from "../../components/Sider";

const Home = ({ public: notAuthenticated }: IHome) => {
  const { alert } = useContext(AlertContext);

  return (
    <HomeWrapper>
      <AlertWrapper>
        {alert && (
          <StyledAlert message={alert.msg} type={alert.type} showIcon />
        )}
      </AlertWrapper>
      <Navbar items={[]} public={notAuthenticated} />
      <Layout>
        <DashboardStoreContext.Provider value={new DashboardStore()}>
          {!notAuthenticated && <Sider />}
          <Outlet />
        </DashboardStoreContext.Provider>
      </Layout>
    </HomeWrapper>
  );
};

export default observer(Home);
