import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AlertProvider from "./components/AlertProvider";
import AuthProvider from "./components/AuthProvider";
import Router from "./router";
import { initPosthog } from "./utils/posthog";
import "antd/dist/reset.css";

const App = () => {
  useEffect(initPosthog, [initPosthog]);

  return (
    <AuthProvider>
      <BrowserRouter>
        <AlertProvider>
          <Router />
        </AlertProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
