import styled from "styled-components";

export const StyledContainer = styled.main`
  min-width: 500px;
  overflow-x: auto;
  overflow-y: auto;
  padding: 50px;
  text-align: center;
  width: 100%;
`;

export const StyledFaturaContainer = styled.div<{ isCancelled: boolean }>`
  ${({ isCancelled }) => isCancelled && `text-decoration: line-through;`}
`;
