import { AxiosResponse } from "axios";
import { ENDPOINTS } from "../../utils/endpoints";
import API_CALL from "../api";
import { Codes, User } from "../../store/mobx/Dashboard/model";

export const getUser = async (): Promise<AxiosResponse<User, any>> => {
  return await API_CALL({
    method: "GET",
    path: ENDPOINTS.user,
  });
};

export const updateAccount = async (
  method: "PUT" | "DELETE",
  code: string
): Promise<AxiosResponse<Codes, any>> => {
  return await API_CALL({
    method,
    path: `${ENDPOINTS.customerCode}/${code}`,
  });
};
